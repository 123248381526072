//@import '@nx-bundesliga/bundesliga-com-styles/themes';
//@import '@nx-bundesliga/bundesliga-com-styles/variables';

/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzM3NDc0ZiIsIj9lcjwjYzNjOGNhIiwiO2VyPCMyMzJmMzZ$LCIlPmBePCM3ODkwOWMiLCI~ZXI8I2Q3ZGVlMSIsIjtlcjwjNWI3Mzgwfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

//@import '@nx-bundesliga/bundesliga-com-styles/variables';
@import '@nx-bundesliga/bundesliga-com-styles/variables-material';
//@import '@nx-bundesliga/bundesliga-com-styles/mixins';

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@import 'styles/variables';
@import 'styles/breakpoints';
@import 'styles/bootstrap';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

html,
body {
	box-sizing: border-box;
	height: 100%;
}
body {
	margin: 0;
}

$allkeys: map_keys(map_merge($breakpoints, map_merge($overlapping-gt, $overlapping-lt)));
@each $key in $allkeys {
	@include layout-bp($key) {
		.dfl-display-none-#{$key} {
			display: none !important;
		}
		.dfl-display-flex-#{$key} {
			display: flex !important;
		}
	}
}
.d-none {
	display: none;
}

$fc-font-family: 'Roboto';
$fontConfig: mat.m2-define-typography-config(
	$font-family: $fc-font-family,
	$headline-1:
		mat.m2-define-typography-level(
			$font-size: 34px,
			$line-height: 40px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0074em
		),
	$headline-2:
		mat.m2-define-typography-level(
			$font-size: 45px,
			$line-height: 48px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0em
		),
	$headline-3:
		mat.m2-define-typography-level(
			$font-size: 56px,
			$line-height: 56px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: -0.0089em
		),
	$headline-4:
		mat.m2-define-typography-level(
			$font-size: 112px,
			$line-height: 112px,
			$font-weight: 300,
			$font-family: $fc-font-family,
			$letter-spacing: -0.0134em
		),
	//$headline-5: mat.define-typography-level(px),
	//$headline-6: mat.define-typography-level(px),
	$subtitle-1:
		mat.m2-define-typography-level(
			$font-size: 15px,
			$line-height: 24px,
			$font-weight: 500,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0067em
		),
	$subtitle-2:
		mat.m2-define-typography-level(
			$font-size: 16px,
			$line-height: 28px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0094em
		),
	$body-1:
		mat.m2-define-typography-level(
			$font-size: 16px,
			$line-height: 20px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0179em
		),
	$body-2:
		mat.m2-define-typography-level(
			$font-size: 16px,
			$line-height: 24px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0179em
		),
	$caption:
		mat.m2-define-typography-level(
			$font-size: 12px,
			$line-height: 20px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0333em
		),
	$button:
		mat.m2-define-typography-level(
			$font-size: 14px,
			$line-height: 14px,
			$font-weight: 500,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0893em
		),
	$overline:
		mat.m2-define-typography-level(
			$font-size: 20px,
			$line-height: 32px,
			$font-weight: 500,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0075em
		)
);

// Foreground Elements

// Compute font config
//@include mat.core($fontConfig);
@include mat.core();

// Theme Config

body {
	--primary-color: #37474f;
	--primary-lighter-color: #c3c8ca;
	--primary-darker-color: #232f36;
	--text-primary-color: #{$light-primary-text};
	--text-primary-lighter-color: #{$dark-primary-text};
	--text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
	main: #37474f,
	lighter: #c3c8ca,
	darker: #232f36,
	200: #37474f,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text
		)
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
	--accent-color: #78909c;
	--accent-lighter-color: #d7dee1;
	--accent-darker-color: #5b7380;
	--text-accent-color: #{$dark-primary-text};
	--text-accent-lighter-color: #{$dark-primary-text};
	--text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
	main: #78909c,
	lighter: #d7dee1,
	darker: #5b7380,
	200: #78909c,
	// For slide toggle,
	contrast:
		(
			main: $dark-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text
		)
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
	--warn-color: #ff0000;
	--warn-lighter-color: #ffb3b3;
	--warn-darker-color: #ff0000;
	--text-warn-color: #{$light-primary-text};
	--text-warn-lighter-color: #{$dark-primary-text};
	--text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
	main: #ff0000,
	lighter: #ffb3b3,
	darker: #ff0000,
	200: #ff0000,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text
		)
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $theme-primary,
			accent: $theme-accent,
			warn: $theme-warn
		),
		typography: $fontConfig,
		density: 0
	)
);

$altTheme: mat.m2-define-dark-theme(
	(
		color: (
			primary: $theme-primary,
			accent: $theme-accent,
			warn: $theme-warn
		),
		typography: $fontConfig,
		density: 0
	)
);

// Theme Init
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($fontConfig);

// MTX components
@include mtx.all-component-themes($theme);

.force-dark,
html[dark='1'] .theme-alternate {
	@include mat.all-component-colors($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
	padding: 0 1.15em;
	margin: 0 0.65em;
	min-width: 3em;
	line-height: 36.4px;
}

.mat-standard-chip {
	padding: 0.5em 0.85em;
	min-height: 2.5em;
}

.material-icons {
	font-size: 24px;
	font-family: 'Material Icons', 'Material Icons';
	.mat-badge-content {
		font-family: 'Roboto';
	}
}

.spacer {
	flex: 1 1 auto;
}
.w-100 {
	width: 100%;
}
.w-50 {
	width: 50%;
}
.w-25 {
	width: 25%;
}

// cropper style overrides
image-cropper {
	--cropper-outline-color: rgba(255, 255, 255, 0.65);
}
