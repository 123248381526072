// see node_modules/@angular/flex-layout/_mq.scss

// Non-overlapping Material Design breakpoints
// @type map
$breakpoints: (
	xs: (
		begin: 0,
		end: 599.9px
	),
	sm: (
		begin: 600px,
		end: 959.9px
	),
	md: (
		begin: 960px,
		end: 1279.9px
	),
	lg: (
		begin: 1280px,
		end: 1919.9px
	),
	xl: (
		begin: 1920px,
		end: 4999.99px
	)
) !default;

// Overlapping breakpoints that are greater than defined
// Material Design breakpoints
// @type map
$overlapping-gt: (
	gt-xs: 600px,
	gt-sm: 960px,
	gt-md: 1280px,
	gt-lg: 1920px
) !default;

// Overlapping breakpoints that are less than defined
// Material Design breakpoints
// @type map
$overlapping-lt: (
	lt-sm: 599.9px,
	lt-md: 959.9px,
	lt-lg: 1279.9px,
	lt-xl: 1919.9px
) !default;

@mixin layout-bp($bp) {
	@if map-has-key($breakpoints, $bp) {
		$min: map-get(map-get($breakpoints, $bp), begin);
		$max: map-get(map-get($breakpoints, $bp), end);
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if map-has-key($overlapping-gt, $bp) {
		$min: map-get($overlapping-gt, $bp);
		@media (min-width: $min) {
			@content;
		}
	} @else if map-has-key($overlapping-lt, $bp) {
		$max: map-get($overlapping-lt, $bp);
		@media (max-width: $max) {
			@content;
		}
	}
}
